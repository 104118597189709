import { Component, Mixins } from "vue-property-decorator";

import TeamMixin from "../../common/mixins/team.mixin";

@Component({
  components: {
    WalletTransferFundsMenu: () =>
      import(
        "./components/WalletTransferFundsMenu/WalletTransferFundsMenu.vue"
      ),
    CardIssueLimitMenu: () =>
      import("./components/CardIssueLimitMenu/CardIssueLimitMenu.vue"),
    TeamMemberActionsMenu: () =>
      import("./components/TeamMemberActionsMenu/TeamMemberActionsMenu.vue"),
    AutoRefillMenu: () =>
      import("./components/AutoRefillMenu/AutoRefillMenu.vue"),
  },
})
export default class MobileTeam extends Mixins(TeamMixin) {
  private get topBarSuccessButton() {
    return {
      title: this.$vuetify.lang.t("$vuetify.dashboard.search"),
      loading: this.loading,
      listeners: {
        click: this.refreshTeamMembers,
      },
    };
  }
}
